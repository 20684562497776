<template>
	<v-card class="mb-2">
		<v-expansion-panels>
			<v-expansion-panel class="px-2">
				<v-expansion-panel-header expand-icon="mdi-menu-down">
					<v-card-title> Content Sensitivity </v-card-title>
				</v-expansion-panel-header>
				<v-expansion-panel-content eager class="px-8">
					<v-card-text>
						<v-radio-group
							v-model="sensitiveContent.hide"
							mandatory
							row
						>
							<v-radio
								label="Sensitive Content"
								:value="true"
							></v-radio>
							<v-radio
								label="Non-sensitive Content"
								:value="false"
							></v-radio>
						</v-radio-group>
						<div v-if="sensitiveContent.hide">
							<p class="pb-2 red--text">Warning Message</p>
							<v-text-field
								dense
								solo
								flat
								outlined
								v-model="sensitiveContent.warningMsg"
								placeholder="The following page contains images that may be disturbing to some viewers/readers."
							></v-text-field>
						</div>
					</v-card-text>
					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="var(--blue)"
							dark
							@click="saveSensitiveData"
							:loading="loading"
						>
							Save
						</v-btn>
					</v-card-actions>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-card>
</template>
<script>
export default {
	props: {
		payload: {
			type: Object,
			default: () => ({
				hide: false,
				warningMsg: '',
			}),
		},
	},
	data() {
		return {
			sensitiveContent: {
				hide: false,
				warningMsg: '',
			},
			loading: false,
		}
	},
	created() {
		console.log("payload")
		if (this.payload) {
			this.sensitiveContent = this.payload
		}
	},
	methods: {
		saveSensitiveData() {
			this.loading = true
			const projectId = this.$store.state.app.projectId
			if (this.sensitiveContent.hide === false) {
				this.sensitiveContent.warningMsg = null
			}
			let payload = {
				sensitiveContent: this.sensitiveContent,
			}
			this.axios({
				method: 'patch',
				url: `/${projectId}/pages/gallery`,
				data: payload,
			}).then((res) => {
				this.loading = false
				this.sensitiveContent = res.data.meta.sensitiveContent
			}).catch(error=>{
					const { data } = error.response
					if (data.statusCode == 400) {
						this.loading=false
						this.$snackbar.notify({
							message:data.message,
							color: 'black',
						})
					}
			})
		},
	},
}
</script>

<style scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title {
	padding: 0 !important;
}
.v-input--selection-controls {
	padding-top: 0 !important;
	margin-top: 0 !important;
}
::v-deep .v-expansion-panel-header__icon {
	margin-left: auto !important;
}
.v-expansion-panel-header {
	font-size: 1.1rem;
}
</style>